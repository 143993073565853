import React, { useState, useEffect } from "react";
import "./Apps.css";
import "./logo.svg";
//import './Navbar.css'
import bridal1 from "./bridal/1.JPG";
import bridal2 from "./bridal/2.JPG";
import bridal3 from "./bridal/3.JPG";
import bridal4 from "./bridal/4.JPG";
import bridal5 from "./bridal/5.JPG";
import bridal5A from "./bridal/5A.JPG";
import bridal5B from "./bridal/5B.JPG";
import bridal6 from "./bridal/6.JPG";
import bridal7 from "./bridal/7.JPG";
import bridal8 from "./bridal/8.JPG";
import bridal9 from "./bridal/9.JPG";
import bridal10 from "./bridal/10.JPG";
import bridal11 from "./bridal/11.JPG";
import bridal12 from "./bridal/12.JPG";
import bridal13 from "./bridal/13.JPG";
import bridal14 from "./bridal/14.JPG";
import bridal15 from "./bridal/15.JPG";
import bridal16 from "./bridal/16.JPG";
import bridal16A from "./bridal/16A.JPG";
import bridal16B from "./bridal/16B.JPG";
import bridal16C from "./bridal/16C.JPG";
import bridal16D from "./bridal/16D.JPG";
import bridal144 from "./bridal/bridal144.JPG";
import bridal101 from "./bridal/bridal101.JPG";
import bridal102 from "./bridal/bridal102.JPG";
import bridal103 from "./bridal/bridal103.JPG";
import bridal104 from "./bridal/bridal104.JPG";
import bridal105 from "./bridal/bridal105.JPG";
import bridal106 from "./bridal/bridal106.JPG";
import bridal107 from "./bridal/bridal107.JPG";
import bridal108 from "./bridal/bridal108.JPG";
import bridal109 from "./bridal/bridal109.JPG";
import bridal110 from "./bridal/bridal110.JPG";
import bridal111 from "./bridal/bridal111.JPG";
import bridal112 from "./bridal/bridal112.JPG";
import bridal113 from "./bridal/bridal113.JPG";
import bridal114 from "./bridal/bridal114.JPG";
import bridal115 from "./bridal/bridal115.JPG";
import bridal116 from "./bridal/bridal116.JPG";
import bridal117 from "./bridal/bridal117.JPG";
import bridal118 from "./bridal/bridal118.JPG";
import bridal119 from "./bridal/bridal119.JPG";
import bridal120 from "./bridal/bridal120.JPG";
import bridal121 from "./bridal/bridal121.JPG";
import bridal122 from "./bridal/bridal122.JPG";
import bridal123 from "./bridal/bridal123.JPG";
import bridal124 from "./bridal/bridal124.JPG";
import bridal125 from "./bridal/bridal125.JPG";
import bridal126 from "./bridal/bridal126.JPG";
import bridal127 from "./bridal/bridal127.JPG";
import bridal128 from "./bridal/bridal128.JPG";
import bridal129 from "./bridal/bridal129.JPG";
import bridal130 from "./bridal/bridal130.JPG";
import bridal131 from "./bridal/bridal131.JPG";
import bridal132 from "./bridal/bridal132.JPG";
import bridal133 from "./bridal/bridal133.JPG";
import bridal134 from "./bridal/bridal134.JPG";
import bridal135 from "./bridal/bridal135.JPG";
import bridal136 from "./bridal/bridal136.JPG";
import bridal137 from "./bridal/bridal137.JPG";
import bridal138 from "./bridal/bridal138.JPG";
import bridal139 from "./bridal/bridal139.JPG";
import bridal140 from "./bridal/bridal140.JPG";
import bridal141 from "./bridal/bridal141.JPG";
import bridal142 from "./bridal/bridal142.JPG";
import bridal143 from "./bridal/bridal143.JPG";

import bridal16E from "./bridal/16E.JPG";
import bridal16F from "./bridal/16F.JPG";
//import bridal16G from "./bridal/16G.JPG";
import bridal16H from "./bridal/16H.JPG";
import bridal16I from "./bridal/16I.JPG";
import bridal16J from "./bridal/16J.JPG";
import bridal17 from "./bridal/17.JPG";
import bridal17A from "./bridal/17A.JPG";
import bridal17B from "./bridal/17B.JPG";
import bridal17C from "./bridal/17C.JPG";
import bridal18 from "./bridal/18.JPG";
import bridal19 from "./bridal/19.JPG";
import bridal20 from "./bridal/20.JPG";
import bridal21 from "./bridal/21.JPG";
import bridal22 from "./bridal/22.JPG";
import bridal23 from "./bridal/23.JPG";
import bridal24 from "./bridal/24.JPG";
import bridal25 from "./bridal/25.JPG";
import bridal26 from "./bridal/26.JPG";
import bridal27 from "./bridal/27.JPG";
import bridal28 from "./bridal/28.JPG";
import bridal29 from "./bridal/29.JPG";
import bridal30 from "./bridal/30.JPG";
import bridal31 from "./bridal/31.JPG";
import bridal32 from "./bridal/32.JPG";
import bridal33 from "./bridal/33.JPG";
import bridal34 from "./bridal/34.JPG";
import bridal35 from "./bridal/35.JPG";
import bridal35A from "./bridal/35A.JPG";
import bridal35B from "./bridal/35B.JPG";
import bridal36 from "./bridal/36.JPG";
import bridal37 from "./bridal/37.JPG";
import bridal38 from "./bridal/38.JPG";
import bridal39 from "./bridal/39.JPG";
import bridal40 from "./bridal/40.JPG";
import bridal41 from "./bridal/41.JPG";
import bridal42 from "./bridal/42.JPG";
import bridal43 from "./bridal/43.JPG";
import bridal44 from "./bridal/44.JPG";
import bridal45 from "./bridal/45.JPG";
import bridal46 from "./bridal/46.JPG";
import bridal47 from "./bridal/47.JPG";
import bridal48 from "./bridal/48.JPG";
import bridal49 from "./bridal/49.JPG";
import bridal50 from "./bridal/50.JPG";
import bridal51 from "./bridal/51.JPG";
import bridal52 from "./bridal/52.JPG";
import bridal3A from "./bridal/3A.JPG";
import bridal3B from "./bridal/3B.JPG";
import bridal3C from "./bridal/3C.JPG";

import bridal11A from "./bridal/11A.JPG";
import bridal11B from "./bridal/11B.JPG";
import bridal11C from "./bridal/11C.JPG";
import bridal11D from "./bridal/11D.JPG";
import bridal11E from "./bridal/11E.JPG";
import bridal11F from "./bridal/11F.JPG";
import bridal11G from "./bridal/11G.JPG";
import bridal11H from "./bridal/11H.JPG";

import bridalMarch1 from "./bridal/March1.JPG";
import bridalMarch2 from "./bridal/March2.JPG";
import bridalMarch3 from "./bridal/March3.JPG";
import bridalMarch4 from "./bridal/March4.JPG";
import bridalMarch5 from "./bridal/March5.JPG";
import bridalMarch6 from "./bridal/March6.JPG";
import bridalMarch7 from "./bridal/March7.JPG";
import bridalMarch8 from "./bridal/March8.JPG";
import bridalMarch9 from "./bridal/March9.JPG";
import bridalMarch10 from "./bridal/March10.JPG";
import bridalMarch11 from "./bridal/March11.JPG";
import bridalMarch12 from "./bridal/March12.JPG";
import bridalMarch13 from "./bridal/March13.JPG";

import bridalAug231 from "./bridal/BridalAug231C.JPG";
import bridalAug232 from "./bridal/BridalAug232.JPG";
import bridalAug233 from "./bridal/BridalAug233A.JPG";
import bridalAug234 from "./bridal/BridalAug234B.JPG";
import bridalAug235 from "./bridal/BridalAug235.JPG";
import bridalAug236 from "./bridal/BridalAug236.JPG";
import bridalAug237 from "./bridal/BridalAug237.JPG";
import bridalAug238 from "./bridal/BridalAug238.JPG";
import bridalAug239 from "./bridal/BridalAug239A.JPG";
import bridalAug2310 from "./bridal/BridalAug2310.JPG";
import bridalAug2311 from "./bridal/BridalAug2311.JPG";
import bridalAug2312 from "./bridal/BridalAug2312A.JPG";
import bridalAug2313 from "./bridal/BridalAug2313.JPG";
import bridalAug2314 from "./bridal/BridalAug2314.JPG";
import bridalAug2315 from "./bridal/BridalAug2315.JPG";
import bridalAug2316 from "./bridal/BridalAug2316.JPG";

import bridalnov12023 from "./bridal/nov1-2023.JPG";
import bridalnov22023 from "./bridal/nov2-2023.JPG";
import bridalnov32023 from "./bridal/nov3-2023.JPG";
import bridalnov42023 from "./bridal/nov4-2023.JPG";
import bridalnov52023 from "./bridal/nov5-2023.JPG";
import bridalnov62023 from "./bridal/nov6-2023.JPG";
import bridalnov72023 from "./bridal/nov7-2023.JPG";
import bridalnov82023 from "./bridal/nov8-2023.JPG";
import bridalnov92023 from "./bridal/nov9-2023.JPG";
import bridalnov102023 from "./bridal/nov10-2023.JPG";
import bridalnov112023 from "./bridal/nov11-2023.JPG";
import bridalnov122023 from "./bridal/nov12-2023.JPG";
import bridalnov132023 from "./bridal/nov13-2023.JPG";
import bridalnov142023 from "./bridal/nov14-2023.JPG";
import bridalnov152023 from "./bridal/nov15-2023.JPG";
import bridalnov162023 from "./bridal/nov16-2023.JPG";
import bridalnov172023 from "./bridal/nov17-2023.JPG";
import bridalnov182023 from "./bridal/nov18-2023.JPG";
import bridalnov192023 from "./bridal/nov19-2023.JPG";
import bridalnov202023 from "./bridal/nov20-2023.JPG";
import bridalnov212023 from "./bridal/nov21-2023.JPG";
import bridalnov222023 from "./bridal/nov22-2023.JPG";
import bridalnov232023 from "./bridal/nov23-2023.JPG";

import bridal1march2024 from "./bridal/bridal1march2024.JPG";
import bridal2march2024 from "./bridal/bridal2march2024.JPG";
import bridal3march2024 from "./bridal/bridal3march2024.JPG";
import bridal4march2024 from "./bridal/bridal4march2024.JPG";
import bridal5march2024 from "./bridal/bridal5march2024.JPG";
import bridal6march2024 from "./bridal/bridal6march2024.JPG";
import bridal7march2024 from "./bridal/bridal7march2024.JPG";
import bridal8march2024 from "./bridal/bridal8march2024.JPG";
import bridal9march2024 from "./bridal/bridal9march2024.JPG";
import bridal10march2024 from "./bridal/bridal10march2024.JPG";
import bridal11march2024 from "./bridal/bridal11march2024.JPG";
import bridal12march2024 from "./bridal/bridal12march2024.JPG";
import bridal13march2024 from "./bridal/bridal13march2024.JPG";
import bridal14march2024 from "./bridal/bridal14march2024.JPG";
import bridal15march2024 from "./bridal/bridal15march2024.JPG";
import bridal16march2024 from "./bridal/bridal16march2024.JPG";
import bridal17march2024 from "./bridal/bridal17march2024.JPG";
import bridal18march2024 from "./bridal/bridal18march2024.JPG";
import bridal19march2024 from "./bridal/bridal19march2024.JPG";
import bridal20march2024 from "./bridal/bridal20march2024.JPG";
import bridal21march2024 from "./bridal/bridal21march2024.JPG";



import party1 from "./party/1.JPG";
import party2 from "./party/2.JPG";
import party3 from "./party/3.JPG";
import party4 from "./party/4.JPG";
import party5 from "./party/5.JPG";
import party6 from "./party/6.JPG";
import party7 from "./party/7.JPG";
import party8 from "./party/8.JPG";
import party9 from "./party/9.JPG";
import party10 from "./party/10.JPG";
import party11 from "./party/11.JPG";
import party12 from "./party/12.JPG";
import party13 from "./party/13.JPG";
import party14 from "./party/14.JPG";
import party15 from "./party/15.JPG";
import party16 from "./party/16.JPG";
import party17 from "./party/17.JPG";
import party18 from "./party/18.JPG";
import party19 from "./party/19.JPG";
import party20 from "./party/20.JPG";
import party21 from "./party/21.JPG";
import party22 from "./party/22.JPG";
import party23 from "./party/23.JPG";
import party24 from "./party/24.JPG";
import party25 from "./party/25.JPG";
import party26 from "./party/26.JPG";
import party27 from "./party/27.JPG";
import party28 from "./party/28.JPG";
import party29 from "./party/29.JPG";
import party30 from "./party/30.JPG";
import party31 from "./party/31.JPG";
import party32 from "./party/32.JPG";
import party33 from "./party/33.JPG";
import party34 from "./party/34.JPG";
import party35 from "./party/35.JPG";
import party36 from "./party/36.JPG";
import party37 from "./party/37.JPG";
import party38 from "./party/38.JPG";
import party39 from "./party/39.JPG";
import party40 from "./party/40.JPG";

import casual1 from "./casual/1.JPG";
import casual2 from "./casual/2.JPG";
import casual3 from "./casual/3.JPG";
import casual4 from "./casual/4.JPG";
import casual5 from "./casual/5.JPG";
import casual6 from "./casual/6.JPG";
import casual7 from "./casual/7.JPG";
import casual8 from "./casual/8.JPG";
import casual9 from "./casual/9.JPG";
import casual10 from "./casual/10.JPG";
import casual11 from "./casual/11.JPG";
import casual12 from "./casual/12.JPG";
import casual13 from "./casual/13.JPG";
import casual14 from "./casual/14.JPG";
import casual15 from "./casual/15.JPG";
import casual16 from "./casual/16.JPG";
import casual17 from "./casual/17.JPG";
import casual18 from "./casual/18.JPG";
import casual19 from "./casual/19.JPG";
import casual20 from "./casual/20.JPG";
import casual21 from "./casual/21.JPG";
import casual22 from "./casual/22.JPG";
import casual23 from "./casual/23.JPG";
import casual24 from "./casual/24.JPG";
import casual25 from "./casual/25.JPG";
import casual26 from "./casual/26.JPG";
import casual27 from "./casual/27.JPG";
import casual28 from "./casual/28.JPG";
import casual29 from "./casual/29.JPG";
import casual30 from "./casual/30.JPG";
import casual31 from "./casual/31.JPG";
import casual32 from "./casual/32.JPG";
import casual33 from "./casual/33.JPG";
import casual34 from "./casual/34.JPG";
import casual35 from "./casual/35.JPG";
import casual36 from "./casual/36.JPG";
import casual37 from "./casual/37.JPG";
import casual38 from "./casual/38.JPG";

import { ImageGroup, Image } from "react-fullscreen-image";
import Mailer from "./Mailer";

export default function App() {
  const [photos, setPhotos] = useState([]);
  const [notes, setNotes] = useState("Henna");
  const [title, setTitle] = useState("Bridal Henna");
  useEffect(() => {
    loadPhotos();
  }, []);
  const party_photos = [
    party1,
    party2,
    party3,
    party4,
    party5,
    party6,
    party7,
    party8,
    party9,
    party10,
    party11,
    party12,
    party13,
    party14,
    party15,
    party16,
    party17,
    party18,
    party19,
    party20,
    party21,
    party22,
    party23,
    party24,
    party25,
    party26,
    party27,
    party28,
    party29,
    party30,
    party31,
    party32,
    party33,
    party34,
    party35,
    party36,
    party37,
    party38,
    party39,
    party40,
  ];
  const bridal_photos = [
    bridal1march2024,
    bridal2march2024,
    bridal3march2024,
    bridal4march2024,
    bridal5march2024,
    bridal6march2024,
    bridal7march2024,
    bridal8march2024,
    bridal9march2024,
    bridal10march2024,
    bridal11march2024,
    bridal12march2024,
    bridal13march2024,
    bridal14march2024,
    bridal15march2024,
    bridal16march2024,
    bridal17march2024,
    bridal18march2024,
    bridal19march2024,
    bridal20march2024,
    bridal21march2024,

    bridalnov12023,
    bridalMarch1,
    bridalMarch2,
    bridalnov22023,
    bridalnov32023,
    bridalnov42023,
    bridalnov62023,
    bridalnov52023,
    bridalnov72023,
    bridalnov92023,
    bridalnov132023,
    bridalnov112023,
    bridalnov122023,
    bridalnov142023,
    bridalnov82023,
    bridalnov102023,
    bridalnov172023,
    bridalnov182023,
    bridalnov192023,
    bridalnov222023,
    bridalnov82023,
    bridalnov232023,
    bridalnov162023,
    bridalnov202023,
    bridalMarch3,
    bridalMarch4,
    bridalMarch5,
    bridalMarch6,
    bridalMarch7,
    bridal11A,
    bridal11G,
    bridal11B,
    bridal11C,
    bridal11D,

    bridalAug231,
    bridalAug232,
    bridalAug233,
    bridalAug234,
    bridalAug235,
    bridalAug236,
    bridalAug237,
    bridalAug238,
    bridalAug239,
    bridalAug2310,
    bridalAug2311,
    bridalAug2312,
    bridalAug2313,
    bridalAug2314,
    bridalAug2315,
    bridalAug2316,

    bridalMarch8,
    bridalMarch9,
    bridalMarch10,
    bridalMarch11,
    bridalMarch12,
    bridalMarch13,

    bridal11E,
    bridal11F,
    bridal101,
    bridal104,
    bridal1,
    bridal2,
    bridal3,
    bridal3A,
    bridal3B,
    bridal103,
    bridal105,
    bridal106,
    bridal11H,
    bridal107,
    bridal108,
    bridal110,
    bridal5A,
    bridal5B,
    bridal3C,
    bridal4,
    bridal5,
    bridal6,
    bridal7,
    bridal8,
    bridal10,
    bridal11,
    bridal12,
    bridal13,
    bridal14,
    bridal15,
    bridal16,
    bridal16A,
    bridal16B,
    bridal16C,
    bridal16D,
    bridal102,
    bridal111,
    bridal112,
    bridal113,
    bridal114,
    bridal115,
    bridal116,
    bridal117,
    bridal118,
    bridal119,
    bridal120,
    bridal121,
    bridal122,
    bridal123,
    bridal124,
    bridal125,
    bridal126,
    bridal127,
    bridal128,
    bridal129,
    bridal130,
    bridal131,
    bridal132,
    bridal133,
    bridal134,
    bridal135,
    bridal136,
    bridal137,
    bridal138,
    bridal139,
    bridal140,
    bridal141,
    bridal142,
    bridal143,
    bridal144,

   //bridal16E,
    bridal16F,
    bridal16H,
    bridal16I,
    bridal16J,
    bridal17,
    bridal109,
    bridal17A,
    bridal17B,
    bridal17C,
    bridal18,
    bridal19,
    bridal20,
    bridal21,
    bridal22,
    bridal23,
    bridal24,
    bridal25,
    bridal26,
    bridal27,
    bridal28,
    bridal29,
    bridal30,
    bridal31,
    bridal32,
    bridal33,
    bridal34,
    bridal35,
    bridal35A,
    bridal35B,
    bridal36,
    bridal37,
    bridal38,
    bridal39,
    bridal40,
    bridal41,
    bridal42,
    bridal43,
    bridal44,
    bridal45,
    bridal46,
    bridal47,
    bridal48,
    bridal49,
    bridal50,
    bridal51,
    bridal52,
  ];
  const casual_photos = [
    casual1,
    casual2,
    casual4,
    casual3,
    casual5,
    casual6,
    casual7,
    casual8,
    casual9,
    casual10,
    casual11,
    casual12,
    casual13,
    casual14,
    casual15,
    casual16,
    casual17,
    casual18,
    casual19,
    casual20,
    casual21,
    casual22,
    casual23,
    casual24,
    casual25,
    casual26,
    casual27,
    casual28,
    casual29,
    casual30,
    casual31,
    casual32,
    casual33,
    casual34,
    casual35,
    casual36,
    casual37,
    casual38,
  ];
  const loadPhotos = () => {
    setPhotos(bridal_photos);
  };

  const setBridal = () => {
    setPhotos(bridal_photos);
    setTitle("Bridal Henna");
    setNotes("Henna");
  };
  const setParty = () => {
    setPhotos(party_photos);
    setTitle("Party Henna");
    setNotes("Henna");
  };
  const setCasual = () => {
    setPhotos(casual_photos);
    setTitle("Casual Henna");
    setNotes("Henna");
  };
  const setNote = () => {
    setNotes("");
  };
  const myFunction = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  };

  return (
    <div className="container">
      <meta
        name="keywords"
        content="Henna, Phoenix Henna Artist, Best Henna Artist in Phoenix, Best Bridal Henna Artist in Phoenix, Best Henna Artist in Arizona, Best Henna Artist in Phoenix near me, Mehandi Designs and Tattoo in Phoenix, AZ, Mehandi Designs and Tattoo in Phoenix, AZ, Bridal Henna"
      />
      <meta description="Mehandi Designs and Tattoo in Phoenix, AZ"></meta>
      <meta
        property="og:description"
        content="Mehandi Designs and Tattoo in Phoenix, AZ"
      />
      <header className="header-fixed">
        <div className="header-limiter">
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          ></link>
          <h1>
            <a href="#">
              <img src="./mm-logo.jpg" width="100" height="90" />
            </a>
          </h1>
          <span className="fa fa-phone">&nbsp;480-275-9147&nbsp;&nbsp;</span>
          <span className="fa fa-envelope">
            &nbsp;mmhennadesigner@gmail.com&nbsp;&nbsp;&nbsp;
          </span>
          {
            <a
              href="https://www.instagram.com/m_m_henna_art/"
              target="_blank"
              className="fa fa-instagram"
            >
              &nbsp;Instagram
            </a>
          }
          <nav>
            <div className="topnav" id="myTopnav">
            <a  href="#"  className="active" onClick={setBridal}>
                Bridal Henna&nbsp;
              </a>
              <a  href="#"  onClick={setParty}>
                Party Henna&nbsp;
              </a>
              <a  href="#"  onClick={setCasual}>
                Casual Henna&nbsp;
              </a>
              <a  href="#"  onClick={setNote}>
                Contact Us
              </a>
        <a href="javascript:void(0);" className="icon" onClick={myFunction}>
          <i className="fa fa-bars"></i>
        </a>
              {/* <a  href="#"  onClick={setBridal}>
                Bridal Henna&nbsp;
              </a>
              <a  href="#"  onClick={setParty}>
                Party Henna&nbsp;
              </a>
              <a  href="#"  onClick={setCasual}>
                Casual Henna&nbsp;
              </a>
              <a  href="#"  onClick={setNote}>
                Contact Us
              </a> */}
            </div>
          </nav>
        </div>
      </header>
      {notes ? (
        <div>
          <h1>{title}</h1>
          <ImageGroup>
            <ul className="images">
              {photos.map((i) => (
                <li key={i}>
                  <Image src={i} alt="mmhennaart" 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: 'auto',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                  />
                </li>
              ))}
            </ul>
          </ImageGroup>
        </div>
      ) : (
        <div>
          <div>
            <Mailer></Mailer>
          </div>

          <section className="questions">
            <h3>More about me</h3>
            <p>
              Hello, I'm Kalpana from Phoenix Arizona. I will provide henna art
              service for Bridal, Bridal shower parties, Birthdays,
              Anniversaries, EID Parties, Karva chauth parites and any of your
              celebrations.
            </p>

            <h3>After henna, care instructions:</h3>
            <p>
              <li>Wash your hands before hanna application.</li>
              <li>Apply hanna on dry hands.</li>
              <li>Keep hennna design on for 4-8 hours, over night is best.</li>
              <li>Apply sugar and lemon on dry henna.</li>
              <li>Scrape off henna do not wash with water.</li>
              <li>Do not wash with soap and water for atleast 12 hours.</li>
              <li>
                Fresh henna stain is bright orange, gradually it darkens in 24
                hours.
              </li>
              <li>
                Apply oraganic henna 2 days before occasion so that it's stain
                in dark.
              </li>
            </p>
          </section>
        </div>
      )}
      <footer className="footer">
        <p>You are welcome to find out more about my henna art work on</p>
        <ul className="social">
          <a
            href="https://www.facebook.com/M-M-Henna-Art-1100497903493994"
            target="_blank"
            className="fa fa-facebook"
          >
            acebook
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a
            href="https://www.instagram.com/m_m_henna_art/"
            target="_blank"
            className="fa fa-instagram"
          >
            &nbsp;Instagram
          </a>
        </ul>
        <span className="fa fa-phone">&nbsp;480-275-9147&nbsp;&nbsp;</span>
        <span className="fa fa-envelope">
          &nbsp;mmhennadesigner@gmail.com&nbsp;&nbsp;&nbsp;
        </span>
      </footer>
    </div>
  );
}
