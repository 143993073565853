import React, { useState } from "react";
import emailjs from "emailjs-com";

const Mailer = () => {
    const [message, setMessage] = useState("");
    const [email, setEmail] = useState("");
    const [isEnabled, setisEnabled] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_308q5ic",
        "template_asqnmw4",
        e.target,
        "user_uYVZqXJ67ACqIcadx0Cl1"
      )
      .then((res) => {
        e.target.reset();
          setMessage('Your details were sent successfully !');
        })
      .catch((error) => {setMessage('Sent message faild')});
  };
  const handleEmailChange = (evt) =>{
    setEmail(evt.target.value);
    setisEnabled(evt.target.value.length > 0 && evt.target.value.includes('@'));
  }
  return (
    <div>
      <div
        className="container border"
        style={{
          marginTop: "50px",
          width: "50%",
          backgroundImage: `url('https://html.sammy-codes.com/images/background.jpg')`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
          integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
          crossorigin="anonymous"
        />
        <h1 style={{ marginTop: "25px" }}>Contact Us</h1>
        <h5 style={{ marginTop: "25px" }} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hi, I'm Kalpana, please leave your message for my &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;henna services, I will contact you shortly.</h5>
        <form
          className="row"
          style={{ margin: "25px 85px 75px 100px" }}
          from
          onSubmit={sendEmail}
        >
          <label>Your Name*:</label>
          <input type="text" name="name" className="form-control" />
          <label>Your Email*:</label>{}
          <input type="email" name="user_email" className="form-control" onChange={handleEmailChange} value={email}/>
          <label>Message*:</label>
          <textarea name="message" rows="4" className="form-control" />
          <input
            type="submit"
            name="Send"
            className="form-control btn btn-primary"
            style={{ marginTop: "25px" }} disabled={!isEnabled}
          />
          <br></br>
          {message}
        </form>
      </div>
    </div>
  );
};
export default Mailer;
